$(window).ready(function (e) {
  // ------ STICKY FILTER ------
  var observer = new IntersectionObserver(function (entries) {
    if (entries[0].intersectionRatio === 0) {
      document.querySelector("#solution__filter").classList.add("solution__filter--sticky");
    }
    else if (entries[0].intersectionRatio === 1) {
      document.querySelector("#solution__filter").classList.remove("solution__filter--sticky");
    }
  }, { threshold: [0, 1] });
  observer.observe(document.querySelector("#solution__filter-top"));

  // scroll observer
  window.addEventListener('scroll', function () {
    let headerHeight = $('.mainNav.fixed .header').height();
    if ($('.mainNav.fixed').hasClass('active')) {
      $('.solution__filter--sticky').addClass('has-header');
      $('.solution__filter--sticky').css('top', headerHeight);
    }
    else if ($('.solution__filter--sticky').hasClass('has-header')) {
      $('.solution__filter--sticky').removeClass('has-header');
      $('.solution__filter--sticky').css('top', 0);
    }

    // Fix scroll observer time gap
    setTimeout(function () {
      if ($('.mainNav.fixed').hasClass('active')) {
        $('.solution__filter--sticky').css('top', headerHeight);
      }
      else {
        $('.solution__filter--sticky').css('top', 0);
      }
    }, 100);
  }, true)

  // click observer
  $(document).on('click', function (evt) {
    if ($('.sideBarMenu').hasClass('active') || $('.search').hasClass('active')) {
      document.querySelector("#solution__filter").classList.remove("solution__filter--sticky");
    }
  });
  // ------ FILTER STICKY OBSERVER ------


  // ------ FILTER BEHAVIOR (MOBILE) ------
  // Open filterBox (mobile) on Click
  $(".article-list .filterOptions .showFilter").click(function () {
    openFilterBox();
  });

  // Open filterBox
  function openFilterBox () {
    window.scrollTo(0, 0);
    $("body").css("overflow", "hidden");

    var filterBox = $(".article-list .filterBox");
    filterBox.addClass('active-mobile');

    var closeBtn = "<div class='filterBox__close-btn'><button type='button'></button></div>";
    $(".article-list").prepend(closeBtn);
  }

  // Close filterBox on Click
  $(".article-list").on('click', '.filterBox__close-btn', function () {
    closeFilterBox();
  });

  // Close filterBox on window resize
  $(window).resize(function () {
    var filterBox = $(".article-list .filterBox");
    if ($(window).width() >= 992 && filterBox.hasClass('active-mobile')) {
      closeFilterBox();
    }
  });

  // Close filterBox
  function closeFilterBox () {
    $("body").css("overflow", "auto");

    setTimeout(() => {
      uncheckFiltersNotApplyed()
    }, 300);

    var filterBox = $(".article-list .filterBox");
    filterBox.removeClass('active-mobile');

    var closeBtn = $('.filterBox__close-btn');
    closeBtn.remove();
  }
  // ------ FILTER BEHAVIOR (MOBILE) ------

  /**
   * MICHEL's CODE FROM ISSUE 205
   */
  hideDropDown()
  checkUncheckBoxes()
  applyFiltersActionButton()
  clearAllFiltersFromGroup()
  toggleClearAllFiltersBtnVisibility()
  groupAndOrderFilters()
  loadMoreArticles()
});

function loadMoreArticles () {
  $('.btnShowMore a').on('click', function () {
    const start = parseInt($(this).parent().attr('data-start') || 0)
    const page = $('.article-list')
    const service = page.data('article-list-service')

    $.ajax({
      url: service,
      data: {
        start
      },
      success: function (data) {
        if (!jQuery.isEmptyObject(data.gridRestOfContents)) {
          $('.btnShowMore').attr('data-start', start + 6)
          const gridRestOfContents = data.gridRestOfContents
          const amountOfItemsFetched = data.length
          const gridSection = $('.rows-grid')

          if (amountOfItemsFetched < 6) {
            $('.btnShowMore').parent().parent().parent().fadeOut()
          }

          for (const key in gridRestOfContents) {
            let items = ''
            gridRestOfContents[key] && gridRestOfContents[key].forEach(contentData => {
              items += `<div class="col-12 col-md-4">${getHTML(contentData)}</div>`
            });
            gridSection.append(`<div class="row">${items}</div>`)
          }
        }
      }
    })
  })
}

/**
 * Stores information about the last request made.
 * This global var is used so we can abort an ongoing
 * request in case another request is made.
 */
let SEARCH_XHR = undefined

function getStringQueryParams (params) {
  let strParams = "?";

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((val) => {
        strParams += `${key}=${val}&`;
      });
    } else {
      strParams += `${key}=${params[key]}&`;
    }
  });

  return strParams;
}

function applyFilters () {
  $("html").animate({ scrollTop: 0 }, 400);

  const page = $('.article-list')
  const service = page.data('article-list-service')
  const topCaseId = page.data('article-topcaseid')
  const filtersChecked = getFiltersChecked()

  let query = getStringQueryParams(Object.assign(filtersChecked))
  const isFilterCleaning = !query.replace('?', '')
  if (topCaseId) {
    query += `topCaseId=${topCaseId}`
  }

  handleParametersOnURL(filtersChecked)
  toggleClearAllFiltersBtnVisibility()

  SEARCH_XHR && SEARCH_XHR.abort();

  SEARCH_XHR = $.ajax({
    url: service + query,
    success: function (data) {

      if (!isFilterCleaning) {
        $('.hideAfterFilter').hide()
        $('.btnShowMore').parent().parent().parent().fadeOut()
      } else {
        $('.hideAfterFilter').show()
        $('.rows-grid').addClass('articles-grid')
        $('.btnShowMore').parent().parent().parent().fadeIn()
        const contentCarouselButtonPrev = $("[data-portal-region='layoutRegion'] .container-fluid.article-slider button.slick-arrow.slick-prev.slick-disabled")
        if (contentCarouselButtonPrev.length) {
          contentCarouselButtonPrev.trigger("click")
        }
      }

      const firstContentData = data && data.firstContent && data.firstContent[0]
      const threeIntermediariesContentData = data && data.threeIntermediariesContent
      const gridRestOfContentsData = data && data.gridRestOfContents


      // <------------------------ First Content Section ------------------------>
      const firstContentSection = $('.firstContent')
      firstContentSection.empty()

      if (firstContentData) {
        firstContentSection.append(getHTML(firstContentData))
      }


      // <------------------------ Three Contents Section ------------------------>
      const threeIntermediariesContentSection = $('.threeIntermediariesContent')
      threeIntermediariesContentSection.empty()


      if (threeIntermediariesContentData.length) {
        // threeIntermediariesContentSection.show()
        threeIntermediariesContentData.forEach((contentData, index) => {
          const threeIntermediariesContentSection = $('.threeIntermediariesContent')
          const item = `<div class="col-12 col-md-4">${getHTML(contentData)}</div>`
          const section = `<div class="row mt-80 article-item threeIntermediariesContent">${item}</div>`
          threeIntermediariesContentSection.append(item)
        })
      } else {
        // threeIntermediariesContentSection.hide()
      }


      // <------------------------ Grid Content Section ------------------------>
      const gridSection = $('.rows-grid')
      if (!isFilterCleaning) {
        gridSection.removeClass('articles-grid')
      }
      gridSection.empty()

      if (!jQuery.isEmptyObject(gridRestOfContentsData)) {
        gridSection.show()
        let key
        for (key in gridRestOfContentsData) {
          let items = ''
          gridRestOfContentsData[key] && gridRestOfContentsData[key].forEach(contentData => {
            items += `<div class="col-12 col-md-4">${getHTML(contentData)}</div>`
          });
          gridSection.append(`<div class="row">${items}</div>`)
        }
      } else {
        gridSection.hide()
      }

    },
    complete: function (data) {

    },
  });
}

function getHTML (item = {}) {
  // console.log(item)
  return `<div class="article-item__item">
    <a href="${item.url}" title="${item.header}">
      <figure class="img aspectRatio--424x265">
       ${item.mainImage ? `<img src="${item.mainImage.link}" alt="${item.mainImage.alt}" title="${item.mainImage.title}" />` : ''}
        <span class="macroTag ${item.colourTag.color}">${item.colourTag.title}</span>
      </figure>
    </a>
    <span class="subject">${item.relatedTag}</span>
    <h3><a href="${item.url}" title="${item.header}">${item.header}</a></h3>
    <a href="${item.url}" title="${item.header}" class="read-more">Les mer</a>
  </div>`
}

function getFiltersChecked () {
  const inputs = $("input[type=checkbox]:checked");
  const filters = {};

  inputs.each(function (index, ele) {
    const name = ele.getAttribute("name");
    const value = ele.getAttribute("value");

    if (filters[name]) {
      (filters[name].indexOf(value) === -1) && filters[name].push(value);
    } else {
      filters[name] = [value];
    }
  });

  return filters;
}

function getAllParamsFromUrl () {
  var query = location.search.substr(1);
  var params = query.split("&");
  var result = {};
  for (var i = 0; i < params.length; i++) {
    var item = params[i].split("=");

    const key = item[0].replace(/\[|\]/g, "");
    const value = item[1];

    if (!result[key]) result[key] = [value];
    else result[key].push(value);
  }
  return result;
}

/**
 * Changes the query params on the browser's URL
 * @param {*} params
 */
function handleParametersOnURL (params) {
  let urlWithParams = location.origin + location.pathname;

  if (params) {
    const parametersOnURL = Object.keys(params)
      .filter((parameterName) => params[parameterName])
      .filter((parameterName) =>
        Array.isArray(params[parameterName])
          ? params[parameterName].length > 0
          : true
      )
      .map(
        (parameterName) =>
          `${$.param({ [parameterName]: params[parameterName] }, true)}`
      )
      .join("&");

    if (parametersOnURL) {
      urlWithParams += decodeURI(`?${parametersOnURL}`);
    }
  }

  history.pushState(undefined, undefined, urlWithParams);
}

/**
 * Creates a string with the HTML responsible
 * to show one document on the document list
 * @param {*} pdfInfo
 */

function clearAllFilters () {
  $("[type=checkbox]:checked").each(function (input, el) {
    this.checked = false;
  });

  const viewFiltersBtn = $('#view_filters')
  const titleProp = viewFiltersBtn.prop('title')
  viewFiltersBtn.text(`${titleProp} +`)

  const divSelects = $('.select-selected')

  divSelects.each(function (index, element) {
    const divSelect = $(element)
    resetFilterName(divSelect)
  })

  $('.filterOptions__buttons').removeClass('buttons--clear')

  applyFilters()
}


/**
 * CODE WRITTEN BY MICHEL
 * RELATED TO ISSUE 205
 */

var x, i, j, l, ll, selElmnt, a, b, c, d, e;
/* Look for any elements with the class "custom-select": */
x = document.getElementsByClassName("custom-select");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /* For each element, create a new DIV that will act as the selected item: */
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /* For each element, create a new DIV that will contain the option list: */
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  d = document.createElement("DIV");
  d.setAttribute("class", "select-items__wrapper");

  e = document.createElement("DIV");
  e.setAttribute("class", "select-items__buttons");
  e.innerHTML = `
      <button type='button' class='btn btn-grey cleanSectionFilters'>Fjern alle</button>
      <button type='button' class='btn btn-yellow applySectionFilters'>Lagre</button>
    `;

  for (j = 1; j < ll; j++) {
    /* For each option in the original select element,
    create a new DIV that will act as an option item: */
    c = document.createElement("DIV");

    //create checkbox inside select
    c.setAttribute("class", "checkbox-group");

    const isChecked = selElmnt.options[j].getAttribute('checked') === 'checked' ? "checked" : ''
    const id = selElmnt.options[j].dataset.id || ''
    const name = selElmnt.getAttribute('name')
    const value = selElmnt.options[j].getAttribute('value')

    const disabled = selElmnt.options[j].dataset.disabled
    disabled === 'true' && c.classList.add('disabled')

    c.innerHTML = `
      <input type="checkbox" ${isChecked} name="${name}" value="${value}" ${id ? 'data-id="' + id + '"' : ''} />
      <div class="check"></div>
      <label>${selElmnt.options[j].innerHTML}</label>
    `

    c.addEventListener("click", function (e) {
      /* When an item is clicked, update the original select box,
      and the selected item: */
      var y, i, k, s, h, sl, yl;
      s = this.parentNode.parentNode.getElementsByTagName("select")[0];

      sl = s ? s.length : 0;

      h = this.parentNode.previousSibling;
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName("same-as-selected");
          yl = y.length;
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute("class");
          }
          this.setAttribute("class", "same-as-selected");
          break;
        }
      }
      h && h.click();
    });
    d.appendChild(c); //insert options in select-items__wrapper
    b.appendChild(d); //insert select-items__wrapper in select-item
    b.appendChild(e); //insert selet-items__butons in select-item
  }
  x[i].appendChild(b);
  a.addEventListener("click", function (e) {
    /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle("select-hide");
    this.classList.toggle("select-arrow-active");

    // if its not mobile unckeched the checkboxes that were not applyed
    if (window.innerWidth >= 992) {
      setTimeout(() => {
        uncheckFiltersNotApplyed()
      }, 300);
    }

  });
  $(selElmnt).remove()
}

function closeAllSelect (elmnt) {
  /* A function that will close all select boxes in the document,
  except the current select box: */
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
function hideDropDown () {
  // hide dropdown when click anywhere
  $(document).click(function () {
    $(".select-items").addClass('select-hide')
    $('.select-selected').removeClass('select-arrow-active')

    if (window.innerWidth >= 992) {
      uncheckFiltersNotApplyed()
    }
  });

  /* Clicks within the dropdown won't make it past the dropdown itself */
  $(".select-items").click(function (e) {
    e.stopPropagation();
  });
}

/**
 * Verifies which filters are set on the URL parameters
 * and which filters are checked. If there's filters
 * checked that is not on the url params, we uncheck them.
 */
function uncheckFiltersNotApplyed () {
  const urlFilters = getAllParamsFromUrl() || {}
  const checkedInputs = $('.filterBox').find(`input[type=checkbox]`)

  checkedInputs.each(function () {
    const input = $(this)
    const name = input.prop('name')
    const value = input.prop('value')

    this.checked = !!(urlFilters[name] && (urlFilters[name].indexOf(value) !== -1))
  })
}

function checkUncheckBoxes () {
  const checkbox = $('.checkbox-group input')
  // console.log(checkbox)
  checkbox.on("click", null, function () {
    $(this).toggleClass('checked')


    if (this.name === 'type') {
      const subtypes = $(this).parent().siblings().find("input[name='subType']:checked")
      subtypes.each(function (e) { this.checked = false })
    }

  })
}

function applyFiltersActionButton () {
  $('.applySectionFilters').on("click", null, function () {
    $(".select-items").addClass('select-hide')
    $('.select-selected').removeClass('select-arrow-active')

    const cleanBtn = $(this)
    const selectedInputs = cleanBtn.parent().siblings('.select-items__wrapper').find('input:checked')
    const divSelect = cleanBtn.parent().parent().siblings('.select-selected')

    if (selectedInputs.length) {
      const filterTitle = divSelect.text().split(' (')[0]
      divSelect.text(`${filterTitle} (${selectedInputs.length})`)
    }
    else {
      const filterTitle = divSelect.text().split(' (')[0]
      divSelect.text(`${filterTitle}`)
    }

    applyFilters()
  })
}

function clearAllFiltersFromGroup () {
  $('.cleanSectionFilters').on('click', function (e) {
    const ele = $(this)
    const sectionInputs = ele.parent().siblings('.select-items__wrapper').find('input')

    sectionInputs.each(function () {
      this.checked = false;
    })

    const divSelect = ele.parent().parent().siblings('.select-selected')

    resetFilterName(divSelect)
    // Shows the button that is visible when no filters are selected
    $('.btnShowMore').parent().parent().parent().show()
    applyFilters()
  })
}

function resetFilterName (divSelect) {
  if (!divSelect) return
  divSelect.removeClass("select-arrow-active");
  divSelect.next().addClass("select-hide");
  const filterTitle = divSelect.text().split(" (")[0];
  divSelect.text(filterTitle);
}

function disableUnavailableFilters (availableFilters = []) {
  const solutionInputs = $("input[type=checkbox]");

  solutionInputs.each(function () {
    const input = $(this);
    const id = input.attr("data-id") || input.attr("value");

    if (availableFilters.indexOf(id) === -1 && !input.prop('checked')) {
      input.parent().addClass("disabled");
    } else {
      input.parent().removeClass("disabled");
    }
  });
}

function groupAndOrderFilters () {
  const parentWrapper = $('.select-items__wrapper')

  function sortFilters (f1, f2) {
    const titleF1 = $(f1).find('label').text()
    const titleF2 = $(f2).find('label').text()

    return titleF1.localeCompare(titleF2)
  }

  parentWrapper.each(function () {
    const enabledFilters = $(this).find('div[class="checkbox-group"]')
    const disabledFilters = $(this).find('.checkbox-group.disabled')

    // removes all filtes from wrapper
    // enabledFilters.remove()
    disabledFilters.remove()

    // order enabled and disabled filters alphabetically
    const orderedEnabledFilters = enabledFilters.get().sort(sortFilters)
    const orderedDisabledFilters = disabledFilters.get().sort(sortFilters)

    // put the elements back on the wrapper
    $(this).append(orderedEnabledFilters)
    $(this).append(orderedDisabledFilters)
  })
}

function toggleClearAllFiltersBtnVisibility () {
  ($("[type=checkbox]:checked").length > 0) ? $(".clearFilter").show() : $(".clearFilter").hide()
}

function mobileFilters (type) {
  const parentSection = $(".filterBox.active-mobile");

  if (type === "clean") {
    const checkedFilters = $("input[type=checkbox]:checked");
    checkedFilters.prop("checked", false);
    $(".filterOptions__buttons").removeClass("buttons--clear");
  }

  handleFilterGroupName();

  const inputs = $(".filterBox.active-mobile").find(
    "input[type=checkbox]:checked"
  );
  const viewFiltersBtn = $("#view_filters");
  const titleProp = viewFiltersBtn.prop("title");
  const newTitle =
    inputs.length > 0 ? `${titleProp} (${inputs.length}) +` : `${titleProp} +`;

  viewFiltersBtn.text(newTitle);

  inputs.length > 0 ? $(".filterOptions__buttons").addClass("buttons--clear") : $(".filterOptions__buttons").removeClass("buttons--clear");

  $(".filterBox__close-btn").find("button").click();
  applyFilters()
}

function handleFilterGroupName () {
  const divSelects = $(".select-selected");

  divSelects.each(function () {
    const divSelect = $(this);
    const inputs = divSelect
      .siblings(".select-items")
      .find("input[type=checkbox]:checked");

    if (inputs.length > 0) {
      const filterTitle = divSelect.text().split(" (")[0];
      divSelect.text(`${filterTitle} (${inputs.length})`);
    } else {
      resetFilterName(divSelect);
    }
  });
}

// $("#filter-mobile").click(function(){
//   $("#solution__filter").toggle();
// });
